import React from "react";
import {Link} from "react-router-dom";

class Complete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkmark: "https://spare-cdn.s3.us-west-1.amazonaws.com/images/checkmark.png"
        }
    }

    render() {
        return (
            <div className="dashboard-container d-flex flex-column justify-content-center align-items-center vh-100">
            <div className="text-center">
                <img src={this.state.checkmark} alt="checkmark" style={{width:"50px", marginBotton: "10px"}} />
                <h2>Transaction Completed</h2>
                <p>Please take the cash from your merchant. Your cash request is complete.</p>
                <input
                className="universal-btn"
                type="submit"
                value="Return to Home"
                onClick={() => {
                    this.props.history.push("/dashboard");
                }}
                />
            </div>
            </div>
        )
    }
}

export default Complete;
