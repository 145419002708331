import React from 'react';
import {bindActionCreators} from "redux";
import {tokenAction} from "../redux-stuffs/actions/token_action";
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";
import Header from "../components/Header";

class Profile extends React.Component {

    render() {
        // const {isToggled} = this.state
        return (
            <div>
                <Header/>
                <div className="help-container d-flex flex-column justify-content-center align-items-center vh-100">
                    <div className='spare'><h2>Account Settings</h2></div>
                    
                    <div className="help-button">
                        <Link to="/edit-profile" className="help-button btn">
                        Profile
                        </Link>
                        <Link to="/change-password" className="help-button btn">
                        Change Password
                        </Link>
                        {/* <Link to="/change-pin" className="help-button btn">
                        Change Verification Pin
                        </Link> */}
                    </div>
                </div>
            </div>
        );
    };
    }

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            tokenAction: tokenAction,
        },
        dispatch,
    );

const mapStateToProps = state => {
    return {
        token: state.token,
        profile: state.profile
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Profile));
