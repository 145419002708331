import React, { Component } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import Setup1 from './Setup1';
import Setup2 from './Setup2';
import Setup3 from './Setup3';
import Setup4 from './Setup4';

class SignupFlow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completedSteps: [],
    };
  }

  componentDidMount() {
    // Add event listener for back navigation handling
    window.addEventListener('popstate', this.handleBackButton);
  }

  componentWillUnmount() {
    // Clean up event listener
    window.removeEventListener('popstate', this.handleBackButton);
  }

  handleBackButton = (e) => {
    const currentStep = parseInt(this.props.location.pathname.split('setup')[1]);

    if (window.confirm("Are you sure you want to go back? You will have to resubmit the information again to move forward.")) {
      if (currentStep > 1) {
        this.props.history.push(`/signup-flow/setup${currentStep - 1}`);
      } else {
        this.props.history.push("/signup");
      }
    } else {
      this.props.history.push(this.props.location.pathname);
    }
  };

  goToStep = (step) => {
    const currentStep = parseInt(this.props.location.pathname.split('setup')[1]);

    // Mark the current step as completed when moving to the next step
    if (!this.state.completedSteps.includes(currentStep)) {
      this.setState((prevState) => ({
        completedSteps: [...prevState.completedSteps, currentStep],
      }));
    }

    // Navigate to the next step
    this.props.history.push(`/signup-flow/setup${step}`);
  };

  goBack = () => {
    const { pathname } = this.props.location;
    const currentStep = parseInt(pathname.split('setup')[1]);
    if (currentStep > 1) {
      this.props.history.push(`/signup-flow/setup${currentStep - 1}`);
    }
  };

  render() {
    const { completedSteps } = this.state;

    return (
      <div className="signup-container">
        {/* Progress Bar */}
        <div className="progress-bar">
          <div className="stepper">
            <div className="step">
              <div className={`circle ${this.props.location.pathname.includes('setup1') ? 'active' : ''}`}>
                {completedSteps.includes(1) ? '✔' : '1'}
              </div>
              <div className="step-info" data-mobile-text="Set-up">
                <h4>Account Setup</h4>
                <p>Provide complete information of yourself.</p>
              </div>
            </div>

            <div className={`line ${this.props.location.pathname.includes('setup2') ? 'active' : ''}`}></div>

            <div className="step">
              <div className={`circle ${this.props.location.pathname.includes('setup2') ? 'active' : ''}`}>
                {completedSteps.includes(2) ? '✔' : '2'}
              </div>
              <div className="step-info" data-mobile-text="Scan">
                <h4>Scan ID Card</h4>
                <p>Scan front and back of your ID card.</p>
              </div>
            </div>

            <div className={`line ${this.props.location.pathname.includes('setup3') ? 'active' : ''}`}></div>

            <div className="step">
              <div className={`circle ${this.props.location.pathname.includes('setup3') ? 'active' : ''}`}>
                {completedSteps.includes(3) ? '✔' : '3'}
              </div>
              <div className="step-info" data-mobile-text="Face">
                <h4>Face Scan</h4>
                <p>Capture a picture of yourself.</p>
              </div>
            </div>

            <div className={`line ${this.props.location.pathname.includes('setup4') ? 'active' : ''}`}></div>

            <div className="step">
              <div className={`circle ${this.props.location.pathname.includes('setup4') ? 'active' : ''}`}>
                {completedSteps.includes(4) ? '✔' : '4'}
              </div>
              <div className="step-info" data-mobile-text="Phone">
                <h4>Phone Number Verification</h4>
                <p>Enter the code sent to your phone.</p>
              </div>
            </div>
          </div>
        </div>

        {/* Step Content */}
        <div className="step-content">
          <Switch>
            <Route exact path="/signup-flow/setup1" component={() => <Setup1 goToStep={this.goToStep} goBack={this.goBack} />} />
            <Route exact path="/signup-flow/setup2" component={() => <Setup2 goToStep={this.goToStep} goBack={this.goBack} />} />
            <Route exact path="/signup-flow/setup3" component={() => <Setup3 goToStep={this.goToStep} goBack={this.goBack} />} />
            <Route exact path="/signup-flow/setup4" component={() => <Setup4 goToStep={this.goToStep} goBack={this.goBack} />} />
            <Route path="*" component={() => <Setup1 goToStep={this.goToStep} goBack={this.goBack} />} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(SignupFlow);
