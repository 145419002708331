import React from 'react';
import {bindActionCreators} from "redux";
import {tokenAction} from "../redux-stuffs/actions/token_action";
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";
import {Modal, Form, Input, Button, Select, DatePicker, Card, Row, Col, Radio, Typography} from "antd";
import Header from "../components/Header";
import axios from 'axios';
import { accessTokenState, emailState } from '../atoms';
import withRecoil from '../withRecoil';
import api from "../api/api";
import { us_state_abbrevs_names} from "../locations"

const { Title, Text } = Typography;

const months_arr = {
    1: "January", 2: "February", 3: "March", 4: "April", 5: "May", 6: "June",
    7: "July", 8: "August", 9: "September", 10: "October", 11: "November", 12: "December"
};

class CardDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipcode: '',
            ssn: '',
            cvv: '',
            showForm: false,
            cards: [],
            cardNumber: '',
            currentView: 'cardList', // 'cardList' or 'enterAmount'
            cardSelected: null,
            amount: 0,
            fee: 0,
            total: 0,
            errors: {},
        };
        this.calculateTotal = this.calculateTotal.bind(this);
    }

    handleCardInfoSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                fetch(`${api.extra}/api/user`, {
                    method: 'POST',
                    headers: {
                        "Content-type": "application/json"
                    },
                    body: {
                        userToken: this.props.recoilStates.accessTokenState,
                        userInfo: {
                            email: values.email,
                            phone: values.phone,
                            first_name: values.firstName,
                            last_name: values.lastName,
                            address1: values.address1,
                            address2: values.address2 ? values.address2 : "",
                            city: values.city,
                            state: values.state,
                            postal_code: values.zipcode,
                            date_of_birth: values.dob,
                            ssn: values.ssn,
                            ip_address: "127.0.0.1"
                        },
                        userCard: {
                            card_number: values.cardNumber,
                            card_security_code: values.cvv,
                            expiration_date: `${values.exp_month}/${values.exp_year}`,
                            first_name: values.firstName,
                            last_name: values.lastName,
                            street_line_1: values.address1,
                            street_line_2: values.address2 ? values.address2 : "",
                            city: values.city,
                            state: values.state,
                            zip_code: values.zipcode
                        }
                    }
                })
                .then(response => response.json())
                .then(response =>{
                    if(response.status === 200){
                        this.setState({ currentView: 'cardList', cardSelected: null});
                    }
                    else{
                        Modal.error({
                            title: "Error. Please try again.",
                            content: response.body.description,
                            onOk: window.location.reload()
                        })
                    }
                })
            } else {
                console.error('Form is invalid');
            }
        });
    };

    handleDeleteCard = (cardId) => {
        <Modal
            title="Delete Card"
            onYes={
                fetch(`${api.extra}/api/delete_debit_card`, {
                    method: 'POST',
                    headers: {
                        "Content-type": "application/json"
                    },
                    body: {
                        id: cardId,
                        email: this.props.recoilStates.emailState.state
                    }
                })
                .then(response => response.json())
                .then(response => {
                    if(response.status === 200){
                        window.location.reload();
                    }
                    else{
                        Modal.error({
                            title: "Error. Please try again.",
                            content: response.body.description,
                            onOk: window.location.reload()
                        })
                    }
                })
            }
            onCancel={window.location.reload()}
            width={window.innerWidth < 576 ? '100%' : 520}
            >
            Are you sure you want to delete this card?
        </Modal>
    }

    handleUseCardClick = (number) => {
        this.setState({ currentView: 'enterAmount', cardSelected: number });
    };

    calculateTotal = (e) => {
        if(e.target.value){
            this.setState({amount: parseFloat(e.target.value)})
            if(this.state.amount < 0){
                this.setState({amount: Math.abs(e.target.value)});
            }
        }
        else{
            this.setState({amount: 0})
        }

        const fee_schedule = `${api.main}/get_fee_schedule?access_token=${encodeURIComponent(this.props.recoilStates.accessTokenState.state)}&source=${encodeURIComponent("astra_debit")}`;
        fetch(fee_schedule)
        .then(response => response.json())
        .then(response => {
            const feePercentage = response.data[0].percentage / 100;
            const fee = this.state.amount * feePercentage;
            const total = this.state.amount + fee;

            this.setState({
                fee: isNaN(fee) ? 0 : fee,
                total: isNaN(total) ? 0 : total
            });
        })
        .catch(error => {
            console.error('Error fetching fee schedule:', error);
        });
    };

    handleBackToCardList = () => {
        this.setState({ currentView: 'cardList', cardSelected: null});
        const customEvent = { target: { value: 0 } };
        this.setState({ amount: 0 }, () => {
        this.calculateTotal(customEvent);
        });
    };

    handleFundSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // Fund account logic
            } else {
                console.error("Please choose a different card.");
            }
        });
    };

    getCardList = () => {
        console.log(this.props.recoilStates.emailState.state)
        fetch(`${api.extra}/api/cards`, {
            method: 'POST',
            headers: {
                "Content-type": "application/json"
            },
            body: {email: this.props.recoilStates.emailState.state}
        })
        .then(response => response.json())
        .then(response =>{
            this.setState({cards: response.body.cards})
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { firstName, lastName, phone, email, address1, address2, city, state, zipcode, ssn, cvv, cards, cardNumber, showForm, currentView, cardSelected } = this.state;
        const currentYear = new Date().getFullYear();
    
        return (
            <div>
                <Header/>
                {/* Render Enter Amount View */}
                {currentView === 'enterAmount' && cardSelected && (
                    <div className="outer-container">
                        <div className="spare"><h3>Top-up using Debit Card</h3></div>
                        <div className="card-container">
                            <Form onSubmit={this.handleSubmit}>
                                {/* Card Info */}
                                <div className="card-info">
                                    <p className='card-details'>Visa Debit Card **** **** **** {cardSelected.slice(-4)}</p>
                                </div>
        
                                {/* Deposit Amount */}
                                <Form.Item>
                                    {getFieldDecorator('value', {
                                        rules: [{ required: true, message: 'Please enter an amount.' }],
                                    })(
                                        <div className="deposit-box">
                                            <div className='spare' style={{textAlign: "center"}}><h4>Enter deposit amount ($)</h4></div>
                                            <Input
                                                type="text"
                                                id="amount"
                                                placeholder="0.00"
                                                onChange={this.calculateTotal}
                                                style={{ padding: '10px', textAlign: 'center', fontSize: '24px' }}
                                                step="0.01"
                                                min={0}
                                            />
                                        </div>
                                    )}
                                </Form.Item>
                                <div className='divider'></div>
                                {/* Transaction Fee */}
                                <Form.Item>
                                    {getFieldDecorator('fee', {})(
                                        <div className="flex-row">
                                            <label className="flex-label">Transaction Fee</label>
                                            <Input
                                                type="number"
                                                className="readonly-input"
                                                value={this.state.fee} 
                                                style={{ padding: '10px' }}
                                                id="fee"
                                                readOnly
                                            />
                                        </div>
                                    )}
                                </Form.Item>
        
                                {/* Total Amount */}
                                <Form.Item>
                                    {getFieldDecorator('total', {})(
                                        <div className="flex-row">
                                            <label className="flex-label">Total</label>
                                            <Input
                                                type="number"
                                                className="readonly-input"
                                                value={this.state.total} 
                                                style={{ padding: '10px' }}
                                                id="total"
                                                readOnly
                                            />
                                        </div>
                                    )}
                                </Form.Item>
                            </Form>
                        </div>
                        <div>
                           {/* Terms and Conditions */}
                           <Form.Item className="col-12">
                                {getFieldDecorator('termsAndConditions', {
                                    rules: [
                                        { required: true, message: ' ' },
                                        {
                                            validator: (rule, value, callback) => {
                                                if (value) {
                                                    callback();
                                                } else {
                                                    callback('Please review the terms and policies.');
                                                }
                                            }
                                        }
                                    ],
                                })(
                                    <div>
                                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                            <div style={{ flex: '1', marginLeft: "10px"}}>
                                                <input type="checkbox" />
                                            </div>
                                            <div style={{ flex: '100', marginLeft: "10px"}}>
                                                <span>
                                                    I authorize the transaction on this date to SPARE in the amount shown above.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Form.Item> 
                        </div>
                        <div style={{display: "flex", alignItems: "center", width: "100%", justifyContent: "space-evenly"}}>
                                <button className="universal-btn" onClick = {() => {this.handleBackToCardList()}}> 
                                Cancel
                            </button>
                            <button type="submit" className="universal-btn"> 
                                Fund
                            </button>
                        </div>
                    </div>
                )}
    
                {/* Render Default View */}
                {currentView !== 'enterAmount' && !showForm && (
                    <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh', width: '100%', marginTop:"-70px"}}>
                    <div className="container mt-4" style={{ width: '100%', textAlign: 'center' }}>
                        <div className="text-center" style={{ width: '90%', maxWidth: '600px', margin: '0 auto', marginBottom: '20px', position: 'relative' }}>
                            {/* Back Arrow Button */}
                            {this.getCardList()}
                            <button
                                onClick={() => this.props.history.push({ pathname: '/dashboard/payment_choice' })}
                                style={{
                                    position: 'absolute',
                                    left: '0',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    border: 'none',
                                    background: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M15 19l-7-7 7-7"
                                        stroke="#333"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <line
                                        x1="8"
                                        y1="12"
                                        x2="20"
                                        y2="12"
                                        stroke="#333"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                            {/* Title */}
                            <div className="spare">
                                <h2 style={{ margin: '0', display: 'inline-block' }}>Top-up using debit card</h2>
                            </div>
                        </div>

                        {/* Remaining content */}
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                            {cards.map((card, index) => (
                                <div
                                    key={card.id}
                                    style={{
                                        width: '90%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginBottom: '16px',
                                        padding: '16px',
                                        border: '1px solid #e0e0e0',
                                        borderRadius: '8px',
                                        boxShadow: '0 0 0 1px #e0e0e0',
                                        cursor: 'pointer',
                                        maxWidth: '500px',
                                        position: 'relative',
                                    }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
                                        {/* Card Details on the Left */}
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                            <div className="spare" style={{ marginLeft: "10px"}}>
                                                <h4>{`${card.card_company} **** **** **** ${card.last_four_digits}`}</h4>
                                            </div>
                                            <div className="spare" style={{ color: '#666', marginLeft: "10px", marginBottom: "10px"}}>
                                                <p>{`Expiry ${card.expiration_date}`}</p>
                                            </div>
                                        </div>
                                        
                                        {/* Action Buttons on the Right */}
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <button
                                                onClick={(e) => {e.stopPropagation(); this.handleUseCardClick(card.id);}}
                                                style={{ padding: '8px 16px', borderRadius: '20px', backgroundColor: 'white', color: '#4C6EF8', border:"1px solid #4C6EF8" }}
                                            >
                                                Use Card
                                            </button>
                                            <button
                                                style={{ padding: '8px 16px', borderRadius: '20px', backgroundColor: 'white', color: '#4C6EF8', border:"1px solid #4C6EF8"}}
                                                onClick={(e) => {e.stopPropagation(); this.handleDeleteCard(card.id);}}
                                            >
                                                Delete Card
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <br />
                        <div style={{display: "flex", alignItems: "center", width: "100%", justifyContent: "center"}}>
                            <button className="universal-btn" onClick={()=>{this.setState({showForm: "true"})}}>
                                Add New Card
                            </button>
                        </div>
                    </div>
                    </div>
                )}
                {/* Render Add New Card Form */}
                {showForm && (
                    <div style={{ width: '100%', justifyContent: 'center', display: 'flex', padding: "10px"}}>
                    <Form onSubmit={this.handleCardInfoSubmit}>
                        <div className="spare" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "40px"}}><h3>Add New Card</h3></div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Form.Item label="Card Holder First Name" style={{ marginRight: '5px', flex: '1' }}>
                                {getFieldDecorator('firstName', {
                                    rules: [{ required: true, message: 'Please input your first name!' }],
                                })(
                                    <Input />
                                )}
                            </Form.Item>

                            <Form.Item label="Card Holder Last Name" style={{ flex: '1' }}>
                                {getFieldDecorator('lastName', {
                                    rules: [{ required: true, message: 'Please input your last name!' }],
                                })(
                                    <Input />
                                )}
                            </Form.Item>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Form.Item label="Phone" style={{ marginRight: '5px', flex: '1' }}>
                                {getFieldDecorator('phone', {
                                    rules: [
                                        { required: true, message: 'Please input your phone number!' },
                                        { len: 10, message: 'Phone number must be 10 digits long' }
                                    ],
                                })(
                                    <Input />
                                )}
                            </Form.Item>

                            <Form.Item label="Email" style={{ flex: '1' }}>
                                {getFieldDecorator('email', {
                                    rules: [{ required: true, message: 'Please input your email!' }],
                                })(
                                    <Input />
                                )}
                            </Form.Item>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Form.Item label="Address1" style={{ flex: '1' }}>
                                {getFieldDecorator('address1', {
                                    rules: [{ required: true, message: 'Please input your address!' }],
                                })(
                                    <Input />
                                )}
                            </Form.Item>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Form.Item label="Address2" style={{ flex: '1' }}>
                                {getFieldDecorator('address2', {
                                    rules: [{ required: true, message: 'Please input your address!' }],
                                })(
                                    <Input />
                                )}
                            </Form.Item>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Form.Item label="City" style={{ marginRight: '5px', flex: '1' }}>
                                {getFieldDecorator('city', {
                                    rules: [{ required: true, message: 'Please input your city!' }],
                                })(
                                    <Input />
                                )}
                            </Form.Item>

                            <Form.Item label="State" style={{ marginRight: '5px', flex: '1' }}>
                                {getFieldDecorator('state', {
                                    rules: [{ required: true, message: 'Please select your state!' }],
                                })(
                                    <Select>
                                        {Object.entries(us_state_abbrevs_names).map(([stateAbbrev, state]) => (
                                            <Select.Option key={stateAbbrev} value={stateAbbrev}>{state}</Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>

                            <Form.Item label="Zip Code" style={{ flex: '1' }}>
                                {getFieldDecorator('zipcode', {
                                    rules: [
                                        { required: true, message: 'Please input your zip code!' },
                                        { len: 5, message: 'Zip code must be 5 digits long' }
                                    ],
                                })(
                                    <Input />
                                )}
                            </Form.Item>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Form.Item label="Date of Birth" style={{ flex: '1' }}>
                                {getFieldDecorator('dob', {
                                    rules: [{ required: true, message: 'Please input your date of birth!' }],
                                })(
                                    <DatePicker picker="date" />
                                )}
                            </Form.Item>

                            <Form.Item label="Last 4 of SSN" style={{ flex: '1' }}>
                                {getFieldDecorator('ssn', {
                                    rules: [
                                        { required: true, message: 'Please input your SSN!' },
                                        { len: 4, message: 'SSN must be 4 digits' }
                                    ],
                                })(
                                    <Input />
                                )}
                            </Form.Item>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Form.Item label="Debit Card Number" style={{ marginRight: '5px', flex: '1' }}>
                                {getFieldDecorator('cardNumber', {
                                    rules: [
                                        { required: true, message: 'Please input your card number!' },
                                        { len: 16, message: 'Card number must be 16 digits long' }
                                    ],
                                })(
                                    <Input />
                                )}
                            </Form.Item>

                            <Form.Item label="CVV" style={{ flex: '1' }}>
                                {getFieldDecorator('cvv', {
                                    rules: [
                                        { required: true, message: 'Please input your CVV!' },
                                        { len: 3, message: 'CVV must be 3 digits long' }
                                    ],
                                })(
                                    <Input />
                                )}
                            </Form.Item>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Form.Item label="Expiration Month" style={{ marginRight: '5px', flex: '1' }}>
                                {getFieldDecorator('exp_month', {
                                    rules: [{ required: true, message: 'Please select expiration month!' }],
                                })(
                                    <Select>
                                        {Object.entries(months_arr).map(([value, month]) => (
                                            <Select.Option key={value} value={value}>{month}</Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>

                            <Form.Item label="Expiration Year" style={{ flex: '1' }}>
                                {getFieldDecorator('exp_year', {
                                    rules: [{ required: true, message: 'Please select expiration year!' }],
                                })(
                                    <Select>
                                        {Array.from({ length: 51 }, (_, i) => currentYear + i).map(year => (
                                            <Select.Option key={year} value={year}>{year}</Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </div>

                        <Form.Item>
                            <div style={{display: "flex", alignItems: "center", width: "100%", justifyContent: "space-evenly"}}>
                                 <button className="universal-btn" style={{padding: "0px 20px"}} onClick = {() => {window.location.reload()}}> 
                                    Cancel
                                </button>
                                <button type="submit" className="universal-btn" style={{padding: "0px 20px"}}> 
                                    Add Card
                                </button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
                )}
            </div>
        );
    }
}

const WrappedCardDetails = Form.create({ name: 'card_details' })(CardDetails);

export default withRecoil(WrappedCardDetails, [emailState, accessTokenState]);
