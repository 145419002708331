import React from 'react';
import { bindActionCreators } from "redux";
import { tokenAction } from "../redux-stuffs/actions/token_action";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Modal } from 'antd';
import Header from "../components/Header";
import api from "../api/api"

class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cashTransactions: [],
      filteredTransactions: [],
      selectedDate: 'All',
      selectedStatus: 'All Statuses',
      allStatuses: ['All Statuses'],
    };
  }

  componentDidMount = async () => {
    await this.combine();
    this.updateAllStatuses();
  }
  
  updateAllStatuses = () => {
    const { cashTransactions } = this.state;
    const uniqueStatuses = ['All Statuses', ...new Set(cashTransactions.map(transaction => transaction.status))];
    this.setState({ allStatuses: uniqueStatuses });
  }

  combine = async () => {
    const cashReq = `${api.main}/cash_request_history`;
    await this.getCashRequestHistory(cashReq, 0);
    await this.getCashRequestHistory(cashReq, 1);
    await this.getCashRequestHistory(cashReq, 2);
    await this.getP2PRequestHistory();

    const sorted = this.state.cashTransactions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    await this.setState({cashTransactions: sorted})
    await this.setState({filteredTransactions: sorted})

  }

  getCashRequestHistory = (transactions, request) => {
    return new Promise((resolve, reject) => {
      fetch(transactions, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          access_token: this.props.token,
          request_type: request
        })
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(response => {
        if (response.status === 105) {
          this.setState(prevState => ({
            cashTransactions: [...prevState.cashTransactions, ...response.data],
            filteredTransactions: [...prevState.cashTransactions, ...response.data]
          }));
          resolve(response.data);
        } else {
          throw new Error(response.message);
        }
      })
      .catch(error => {
        reject(error);
      });
    });
  }


  getP2PRequestHistory = () => {
    const transactions = `${api.main}/p2p_transaction_history?access_token=${this.props.token}`;
    return new Promise((resolve, reject) => {
      fetch(transactions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(response => {
        if (response.status === 105) {
          this.setState(prevState => ({
            cashTransactions: [...prevState.cashTransactions, ...response.data],
            filteredTransactions: [...prevState.cashTransactions, ...response.data]
          }));
          resolve(response.data);
        } else {
          throw new Error(response.message);
        }
      })
      .catch(error => {
        reject(error);
      });
    });
  }

  determineColor = (content) => {
    switch (content) {
      case 'Failed':
        return 'red';
      case 'Completed':
        return 'green';
      case 'Pending':
        return 'grey';
      default:
        return 'black';
    }
  };

  determineBack = (content) => {
    switch (content) {
      case 'Failed':
        return '#fef3f2';
      case 'Completed':
        return '#ecfdf3';
      case 'Pending':
        return '#f2f4f7';
      default:
        return 'white';
    }
  }

  handleDateFilterChange = (e) => {
    const selectedDate = e.target.value;
    this.setState({ selectedDate }, () => { this.filterTransactions() });
  }

  handleStatusFilterChange = (e) => {
    const selectedStatus = e.target.value;
    this.setState({ selectedStatus }, () => { this.filterTransactions() })
  }

  filterTransactions = () => {
    const { cashTransactions, selectedDate, selectedStatus } = this.state;
    
    let filteredTransactions = cashTransactions;
  
    if (selectedDate !== 'All') {
      filteredTransactions = filteredTransactions.filter(transaction =>
        new Date(transaction.created_at).toDateString() === selectedDate
      );
    }
  
    if (selectedStatus !== 'All Statuses') {
      filteredTransactions = filteredTransactions.filter(transaction =>
        transaction.status === selectedStatus
      );
    }
  
    this.setState({ filteredTransactions });
  }

  render() {
    const { filteredTransactions, selectedDate, selectedStatus, cashTransactions, allStatuses } = this.state;
  
    const allDates = [...new Set(cashTransactions.map(transaction => new Date(transaction.created_at).toDateString()))];
    const sortedAllDates = allDates.sort((a, b) => new Date(b) - new Date(a));
  
    const transactionsByDate = filteredTransactions.reduce((acc, transaction) => {
      const date = new Date(transaction.created_at).toDateString();
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(transaction);
      return acc;
    }, {});
  
    return (
      <div>
        <Header />
        <div className="container mt-4">
          <div className="spare-dvs vh-100 align-items-start">
            <div className="w-100 float-left">
              <div className='spare'><h2>Transaction History</h2></div>
              <br />
              <div className="filter-container">
                <div className='filter-date'>
                  <select value={selectedDate} onChange={this.handleDateFilterChange} style={{borderRadius: "60px", textAlign: "center", width: '100%', padding:"5px", fontSize: "16px"}}>
                    <option value="All">All Dates</option>
                    {sortedAllDates.map(date => (
                      <option key={date} value={date}>{date}</option>
                    ))}
                  </select>
                </div>
                <br />
                <div className='filter-status'>
                  <select value={selectedStatus} onChange={this.handleStatusFilterChange} style={{borderRadius: "60px", textAlign:"center", width: '100%', padding:"5px", fontSize: "16px"}}>
                    {allStatuses.map(status => (
                      <option key={status} value={status}>{status}</option>
                    ))}
                  </select>
                </div>
              </div>
              <br />
              {Object.entries(transactionsByDate).map(([date, transactions]) => (
                <div key={date} style={{marginBottom:"15px"}}>
                  <div className='history-date'>{date}</div>
                  {Array.isArray(transactions) && transactions.map(transaction => (
                    <div key={transaction.cash_request_id}>
                      <div className='history-container'>
                        <div className='history-left'>
                          {/* cash request */}
                          {transaction.cash_request_id &&
                          <div>
                            <p style={{fontWeight:"400", fontSize:"16px"}}>Pick-up Cash</p>
                            <p style={{color: "#747e90"}}>{transaction.name}</p>
                            <p style={{color: "#747e90"}}>{"ID "}{transaction.cash_request_id}</p>
                          </div>
                          }
                          {/* p2p request */}
                          {transaction.registration_id &&
                          <div>
                            <p style={{fontWeight: "bold", color: "#22212a"}}>Account to Account</p>
                            <p style={{fontWeight: "bold", color: "#22212a"}}>{transaction.direction}{" "}{transaction.name}</p>
                            <p style={{color: "#747e90"}}>{"ID "}{transaction.registration_id}</p>
                          </div>
                          }
                        </div>
                        <div className='history-right'>
                          {/* cash request */}
                          {transaction.status &&
                            <div>
                              <p style={{padding:"5px"}}>{transaction.currency}{transaction.amount}</p>
                              <p style={{ color: this.determineColor(transaction.status), backgroundColor: this.determineBack(transaction.status), padding:"5px 10px", borderRadius: "55px"}}>{transaction.status}</p>
                            </div>
                          }
                          {/* p2p request */}
                          {transaction.direction &&
                            <div>
                              <p>{"$"}{transaction.amount}</p>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      tokenAction: tokenAction,
    },
    dispatch,
  );

const mapStateToProps = state => {
  return {
    token: state.token,
    profile: state.profile
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(History));
