import React, { Component } from 'react';
import JsBarcode from 'jsbarcode';

class Barcode extends Component {
  constructor(props) {
    super(props);
    this.barcodeRef = React.createRef();
  }

  componentDidMount() {
    this.generateBarcode();
  }

  componentDidUpdate() {
    this.generateBarcode();
  }

  generateBarcode() {
    if (this.barcodeRef.current) {
      JsBarcode(this.barcodeRef.current, this.props.value, {
        format: 'CODE128',
        lineColor: '#000',
        width: 1,
        height: 100,
        displayValue: false,
      });
    }
  }

  render() {
    return <svg ref={this.barcodeRef}></svg>;
  }
}

export default Barcode;
