import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Input, DatePicker, Modal, Select } from 'antd';
import { us_state_abbrevs_names, countries_names } from "../locations"
import api from '../api/api/index';

class Setup1 extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err && values) {
        console.log("received data")
        this.props.goToStep(2);
      } else {
          console.error('Form is invalid');
      }
   });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <div style={{ position: 'relative', marginBottom: '20px', textAlign: 'center', width: '100%' }}>
          {/* <button onClick={() => this.props.history.push({ pathname: '/signup' })} style={{ position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)', border: 'none', background: 'none', cursor: 'pointer' }}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 19l-7-7 7-7" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <line x1="8" y1="12" x2="20" y2="12" stroke="#333" strokeWidth="2" strokeLinecap="round" />
            </svg>
          </button> */}
          <div className='spare'>
            <h2 style={{ textAlign: "center", margin: "0" }}>Account Setup</h2>
          </div>
        </div>

        {/* Ensure the form and its wrapper take full width */}
        <div style={{ width: '100%', padding: "10px" }}>
          <Form onSubmit={this.handleSubmit} layout="vertical" style={{ width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom:"15px" }}>
              <Form.Item label="Name" style={{ marginRight: '5px', flex: '1', width: '100%' }}>
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: 'Please input your name.' }],
                })(
                  <Input />
                )}
              </Form.Item>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom:"15px" }}>
              <Form.Item label="Phone" style={{ marginRight: '5px', flex: '1', width: '100%' }}>
                {getFieldDecorator('phone', {
                  rules: [
                    { required: true, message: 'Please input your phone number.' },
                    { len: 10, message: 'Phone number must be 10 digits long' }
                  ],
                })(
                  <Input />
                )}
              </Form.Item>

              <Form.Item label="Date of Birth" style={{ flex: '1', width: '100%' }}>
                {getFieldDecorator('dob', {
                  rules: [{ required: true, message: 'Please input your date of birth!' }],
                })(
                  <DatePicker
                    format="DD/MM/YYYY"
                    style={{ width: '100%' }}
                    placeholder="DD/MM/YYYY"
                    allowClear
                    showToday
                    inputReadOnly={false}
                    disabledDate={(current) => current && current > new Date()}
                  />
                )}
              </Form.Item>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom:"15px" }}>
              <Form.Item label="Email" style={{ marginRight: '5px', flex: '1', width: '100%' }}>
                  {getFieldDecorator('email', {
                    rules: [{ required: true, message: 'Please input your email.' }],
                  })(
                    <Input />
                  )}
                </Form.Item>

                <Form.Item label="City" style={{flex: '1', width: '100%' }}>
                {getFieldDecorator('city', {
                  rules: [{ required: true, message: 'Please input your city.' }],
                })(
                  <Input />
                )}
              </Form.Item>
            </div> 

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom:"15px" }}>
              <Form.Item label="State" style={{ marginRight: '5px', flex: '1', width: '100%' }}>
                  {getFieldDecorator('state', {
                      rules: [{ required: true, message: 'Please select your state.' }],
                  })(
                      <Select showSearch>
                          {Object.entries(us_state_abbrevs_names).map(([stateAbbrev, state]) => (
                              <Select.Option key={stateAbbrev} value={stateAbbrev}>{state}</Select.Option>
                          ))}
                      </Select>
                  )}
              </Form.Item>

              <Form.Item label="Zip Code" style={{ marginRight: '5px', flex: '1', width: '100%' }}>
                {getFieldDecorator('zipcode', {
                  rules: [
                    { required: true, message: 'Please input your zip code.' },
                    { len: 5, message: 'Zip code must be 5 digits long' }
                  ],
                })(
                  <Input />
                )}
              </Form.Item>

              <Form.Item label="Country" style={{ flex: '1', width: '100%' }}>
                {getFieldDecorator('country', {
                  initialValue: 'United States',
                  rules: [{ required: true, message: 'Please select your country.' }],
                })(
                  <Select
                    showSearch
                    placeholder="Select a country"
                    style={{ width: '100%' }}
                  >
                    {countries_names.map((country, index) => (
                      <Select.Option key={index} value={country}>
                        {country}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Form.Item>
                <button className="universal-btn" type="submit">Submit</button>
              </Form.Item>
             </div>
          </Form>
        </div>
      </div>
    );
  }
}

const WrappedSetup1 = Form.create({ name: 'setup1' })(Setup1);

export default withRouter(WrappedSetup1);