import React from 'react';
import { bindActionCreators } from "redux";
import { tokenAction } from "../redux-stuffs/actions/token_action";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Header from "../components/Header";

class Help extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChatVisible: false,
    };
    this.showChat = this.showChat.bind(this);
    this.hideChat = this.hideChat.bind(this); 
  }

  showChat() {
    this.setState({ isChatVisible: true });
  }

  hideChat() {
    this.setState({ isChatVisible: false });
  }

  render() {
    const { isChatVisible } = this.state; 

    return (
      <div>
        <Header />
        <div className="help-container d-flex flex-column justify-content-center align-items-center vh-100">
          <div className='spare'><h2>Help & FAQ</h2></div>
          <div className="help-button">
            {!isChatVisible && (
              <button onClick={this.showChat} className="help-button btn">
                Open Chat
              </button>
            )}
            <a href="tel:+1(424)744-0155" rel="noopener noreferrer" className="help-button btn">
              Give us a call at: <br />+1 (424) 744-0155
            </a>
            <Link to="/faq" className="help-button btn">
              FAQ
            </Link>
          </div>
        </div>

        {isChatVisible && (
          <div className="chat-container">
            <button
              onClick={this.hideChat}
              className="exit-chat-button"
            >
              Exit Chat
            </button>

            <iframe
              src="https://tawk.to/chat/5e39c317a89cda5a18841f2d/1i680d228"
              title="SPARE Chat"
              className="chat-iframe"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              style={{ pointerEvents: 'none' }}
            ></iframe>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      tokenAction: tokenAction,
    },
    dispatch,
  );

const mapStateToProps = state => {
  return {
    token: state.token,
    profile: state.profile
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Help));
