import { DefaultValue } from 'recoil';

const sessionStorageEffect = key => ({ setSelf, onSet }) => {
  const savedValue = sessionStorage.getItem(key);
  
  if (savedValue !== null && savedValue !== 'undefined') {
    try {
      setSelf(JSON.parse(savedValue));
    } catch (error) {
      console.error(`Error parsing JSON from sessionStorage for key "${key}":`, error);
      setSelf(''); 
    }
  } else {
    setSelf('');
  }

  onSet(newValue => {
    if (newValue instanceof DefaultValue) {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.setItem(key, JSON.stringify(newValue));
    }
  });
};

export default sessionStorageEffect;