import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { tokenAction } from "../redux-stuffs/actions/token_action";
import { message } from 'antd';
import api from '../api/api/index';
import { withRouter } from "react-router-dom";

class Setup3 extends Component {
  constructor(props) {
    super(props);
    this.videoRef = createRef();
    this.canvasRef = createRef();
    this.state = {
      capturedImage: null
    };
  }

  componentDidMount() {
    this.startCamera();
  }

  // Function to start the camera
  startCamera = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        this.videoRef.current.srcObject = stream;
      })
      .catch((err) => {
        console.error("Error accessing the camera: ", err);
      });
  }

  stopCamera = () => {
    const video = this.videoRef.current;
    const stream = video.srcObject;
    const tracks = stream ? stream.getTracks() : [];

    tracks.forEach(track => track.stop());
    video.srcObject = null;
  };

  // Function to capture the image from the video feed
  captureImage = () => {
    const canvas = this.canvasRef.current;
    const video = this.videoRef.current;
    const context = canvas.getContext('2d');

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Save the captured image and remove the video feed
    const imageData = canvas.toDataURL('image/png');
    this.setState({ capturedImage: imageData });

    this.stopCamera();
  };

  // Function to retake the photo
  retakePhoto = () => {
    this.setState({ capturedImage: null });
    this.startCamera();
  };

  handleSubmit = () => {
    if(this.state.capturedImage){
      // fetch function
      console.log("Image Captured ", this.state.capturedImage)
      this.props.goToStep(4);
    }
    else{
      message.error("Error saving captured image. Please try again");
      this.setState({capturedImage: null})
    }
  }

  render() {
    return (
      <div>
        <div style={{ position: 'relative', marginBottom: '20px', textAlign: 'center', width: '100%' }}>
          {/* <button
            onClick={this.props.goBack}
            style={{ position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)', border: 'none', background: 'none', cursor: 'pointer' }}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 19l-7-7 7-7" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <line x1="8" y1="12" x2="20" y2="12" stroke="#333" strokeWidth="2" strokeLinecap="round" />
            </svg>
          </button> */}
          <div className='spare'>
            <h2 style={{ textAlign: "center", margin: "0" }}>Face Scan</h2>
            <p>Please center your face in the circle and capture your photo.</p>
          </div>
        </div>

        {/* Conditional rendering: if capturedImage exists, display the image and retake button, otherwise show the video feed */}
        <div style={{ position: 'relative', width: '100%', textAlign: 'center' }}>
          {!this.state.capturedImage ? (
            <>
              {/* Video element for the camera feed */}
              <video ref={this.videoRef} autoPlay playsInline className="video-feed">
                <track kind="captions" srcLang="en" label="English" default />
              </video>

              {/* Hidden canvas for capturing the image */}
              <canvas ref={this.canvasRef} style={{ display: 'none' }}></canvas>
              <div>
                <button className="capture-btn" onClick={this.captureImage}>Capture Image</button>
              </div>
            </>
          ) : (
            <>
              <img src={this.state.capturedImage} alt="Captured" className="captured-image" />
              <div>
              <button className="capture-btn" style={{width: "120px"}} onClick={this.retakePhoto}>Retake</button>
              <button className="capture-btn" style={{width: "120px"}} onClick={this.handleSubmit}>Continue</button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      tokenAction: tokenAction,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    token: state.token, 
    profile: state.profile, 
  };
};

// Export the connected Setup3 component
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Setup3));