import React from 'react';
import { bindActionCreators } from 'redux';
import { tokenAction } from '../redux-stuffs/actions/token_action';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal } from 'antd';
import { AiOutlineEdit } from 'react-icons/ai';
import Header from '../components/Header';
import api from '../api/api';
import queryString from 'query-string';

class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profilePic: '',
      newProfilePic: '',
      preview: '',
      name: '',
      phone: '',
      dob: '',
      email: '',
      city: '',
    };
  }

  handleProfilePicChange = (e) => {
    e.preventDefault();
    const newPic = e.target.files[0];
    this.setState({ newProfilePic: newPic }, () => {
      if (newPic) {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.setState({ preview: reader.result });
        };
        reader.readAsDataURL(newPic);
      } else {
        this.setState({ preview: '' });
      }
    });
  };

  saveProfilePicChange = () => {
    if (this.state.newProfilePic !== '') {
      const formData = new FormData();
      formData.append('access_token', this.props.token);
      formData.append('image', this.state.newProfilePic);

      const profileUpdate = `${api.main}/update_user_profile`;
      fetch(profileUpdate, {
        method: 'POST',
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          if (data.status === 105) {
            this.setState({ newProfilePic: '', preview: '' });
            Modal.success({
              title: 'Success',
              content: 'Profile Pic has been successfully updated!',
              centered: true,
              onOk: () => {
                this.fetchProfileDetails(); // Refresh profile details
              },
            });
          } else {
            throw new Error(data.message);
          }
        })
        .catch((error) => {
          Modal.error({
            title: 'Error',
            content: error.message,
            onOk: () => this.setState({ newProfilePic: '', preview: '' }),
          });
        });
    }
    if(this.state.name !== ""){
        // set name in user settings
    }
    if(this.state.dob !== ""){
        // set DOB in user settings
    }
    if(this.state.email !== ""){
        // set email in user settings
    }
    if(this.state.city !== ""){
        // set city in user settings
    }
  };

  componentDidMount() {
    this.fetchProfileDetails();
  }

  fetchProfileDetails = () => {
    let body = {
        access_token: this.props.token,
        device_type: 3,
        device_token: 1,
        app_version: 1
    }
    fetch(`${api.main}/access_token_login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: queryString.stringify(body),
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
    .then(response => {
        const isValidUrl = (string) => {
            try {
              new URL(string);
              return true;
            } catch (_) {
              return false;  
            }
          };
          
          if (typeof response.data.profile_pic === 'string' && isValidUrl(response.data.profile_pic)) {
            this.setState({profilePic: response.data.profile_pic});
          } else {
            console.error('Invalid profile picture URL:', response.data.profile_pic);
            this.setState({profilePic: "https://spare-app.s3.us-west-1.amazonaws.com/images/default_profile_pic.png"});
          }
          this.setState({
            name: response.data.name,
            phone: response.data.phone,
            email: response.data.email
            // dob and city needed
          })
    })
}

  render() {
    const { preview, profilePic, name, phone, dob, email, city } = this.state;

    return (
      <div>
        <Header />
        <div className="container mt-4">
          <div className="profile-container d-flex flex-column align-items-center" style={{ maxWidth: '600px', width: '100%', margin: '0 auto' }}>
            <div style={{ position: 'relative', marginBottom: '20px', textAlign: 'center', width: '100%' }}>
                <button onClick={() => this.props.history.push({pathname: '/profile'})} style={{ position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)', border: 'none', background: 'none', cursor: 'pointer' }}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 19l-7-7 7-7" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <line x1="8" y1="12" x2="20" y2="12" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
                <div className="spare"><h2 style={{textAlign:"center", margin:"0"}}>Profile</h2></div>
            </div>
            <div className="profile-pic-wrapper position-relative mb-4">
              <img
                src={preview || profilePic}
                alt="Profile"
                className="profile-pic"
                style={{ width: '100px', height: '100px', borderRadius: '50%', objectFit:"cover" }}
              />
              <input
                type="file"
                accept="image/*"
                onChange={this.handleProfilePicChange}
                style={{ display: 'none' }}
                ref={(fileInput) => (this.fileInput = fileInput)}
              />
              {/* <AiOutlineEdit
                className="edit-icon"
                onClick={() => this.fileInput.click()}
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  fontSize: '30px',
                  background: 'white',
                  borderRadius: '50%',
                  padding: '5px',
                  boxShadow: '0 0 5px rgba(0,0,0,0.1)',
                }}
              /> */}
            </div>
            {/* Profile Details Form */}
            <div className="profile-details-form w-100">
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  readOnly
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label>Phone Number</label>
                <input
                  type="phone"
                  className="form-control"
                  value={phone}
                  readOnly
                  onChange={(e) => this.setState({ phone: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  readOnly
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>
              {/* <div className="form-group">
                <label>City</label>
                <input
                  type="text"
                  className="form-control"
                  value={city}
                  onChange={(e) => this.setState({ city: e.target.value })}
                />
              </div> */}
              {/* <button
                className="btn btn-primary"
                onClick={this.saveProfilePicChange}
                style={{ marginTop: '10px' }}
              >
                Save Changes
              </button> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      tokenAction: tokenAction,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    token: state.token,
    profile: state.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditProfile));
