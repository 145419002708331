import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Button, Upload, message } from 'antd';
import { MdOutlineFileUpload } from "react-icons/md";
import api from '../api/api/index';

class Setup2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileListFront: [],
      fileListBack: [], 
    };
  }

  handleFrontUpload = (info) => {
    const fileList = info.fileList.slice(-1);
    this.setState({ fileListFront: fileList });
    if (info.file.status === 'done') {
      message.success(`${info.file.name} uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  handleBackUpload = (info) => {
    const fileList = info.fileList.slice(-1);
    this.setState({ fileListBack: fileList });
    if (info.file.status === 'done') {
      message.success(`${info.file.name} uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { fileListFront, fileListBack } = this.state;
    if (fileListFront.length > 0 && fileListBack.length > 0) {
      console.log("Front and back images received:", fileListFront, fileListBack);
      this.props.goToStep(3);
    } else {
      message.error("Please upload both front and back images.");
    }
  };

  render() {
    const { fileListFront, fileListBack } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <div style={{ position: 'relative', textAlign: 'center', width: '100%' }}>
          {/* <button
            onClick={this.props.goBack}
            style={{ position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)', border: 'none', background: 'none', cursor: 'pointer' }}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 19l-7-7 7-7" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <line x1="8" y1="12" x2="20" y2="12" stroke="#333" strokeWidth="2" strokeLinecap="round" />
            </svg>
          </button> */}
          <div className='spare'>
            <h2 style={{ textAlign: "center", margin: "0" }}>Scan ID Card</h2>
          </div>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom:"15px", padding: "5px 5px"}}>
          <div className='spare'>
            <p style={{ textAlign: "center", margin: "0"}}>Please make sure the images are well-lit and clear.</p>
          </div>
        </div>

        <div style={{width: "100%"}}>
          <Form onSubmit={this.handleSubmit}>
            {/* Upload Front Side */}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', marginBottom:"15px", height: "200px"}}>
              <Form.Item>
                {getFieldDecorator('frontImage', {
                  rules: [{ required: true, message: 'Please upload the front side of your ID card' }],
                })(
                  <Upload
                    name="frontImage"
                    listType="picture"
                    maxCount={1}
                    multiple={false}
                    fileList={fileListFront} 
                    onChange={this.handleFrontUpload}
                    onRemove={() => this.setState({ fileListFront: [] })}
                    beforeUpload={() => false} 
                    accept="image/*"
                  >
                    <Button size="large">
                      <MdOutlineFileUpload style={{marginRight:"5px"}}/>
                      {fileListFront.length > 0 ? 'Replace Upload' : 'Upload Front'}
                    </Button>
                  </Upload>
                )}
              </Form.Item>

              {/* Upload Back Side */}
              <Form.Item>
                {getFieldDecorator('backImage', {
                  rules: [{ required: true, message: 'Please upload the back side of your ID card' }],
                })(
                  <Upload
                    name="backImage"
                    listType="picture"
                    maxCount={1}
                    multiple={false}
                    fileList={fileListBack} 
                    onChange={this.handleBackUpload}
                    onRemove={() => this.setState({ fileListBack: [] })}
                    beforeUpload={() => false}
                    accept="image/*"
                  >
                    <Button size="large">
                      <MdOutlineFileUpload style={{marginRight:"5px"}}/>
                      {fileListBack.length > 0 ? 'Replace Upload' : 'Upload Back'}
                    </Button>
                  </Upload>
                )}
              </Form.Item>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom:"15px" }}>
              <Form.Item>
                <button type="submit" className="universal-btn">
                  Submit
                </button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const WrappedSetup2 = Form.create({ name: 'setup2' })(Setup2);

export default withRouter(WrappedSetup2);