import React from "react";
import {Button} from "antd";
import {bindActionCreators} from "redux";
import {logout} from "../redux-stuffs/actions/token_action";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Link} from "react-router-dom";
import api from "../api/api/index";
import queryString from "query-string";


class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            profilePic: '',
            name:'',
            phone:'',
            email:''
        }
    }

    toggleDropdown = () => {
        this.setState({isOpen: !this.state.isOpen});
    };

    componentDidMount() {
        if(!this.props.token) {
            this.props.history.push({
                pathname: '/'
            })
        } else {
        this.fetchProfilePic();
        //this.profilePicInterval = setInterval(this.fetchProfilePic, 60000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.profilePicInterval);
    }

    logout = () => {
        this.props.logout()
            .then(_ => {
                this.props.history.push({
                    pathname: '/login'
                })
            })
    };

    fetchProfilePic = () => {
        fetch(`${api.main}/access_token_login`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: queryString.stringify({
                access_token: this.props.token,
                device_type: 3,
                device_token: 1,
                app_version: 1
            }),
          })
          .then(response => {
            if (!response.ok) {
              // Handle HTTP errors
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json(); // Parse the response body to JSON
          })
        .then(response => {
            //console.log("data: ", response.data.name, response.data.phone, response.data.email)

            const isValidUrl = (string) => {
                try {
                  new URL(string);
                  return true;
                } catch (_) {
                  return false;  
                }
              };
              this.setState({
                name: response.data.name,
                phone: response.data.phone,
                email: response.data.email
              })
              if (typeof response.data.profile_pic === 'string' && isValidUrl(response.data.profile_pic)) {
                this.setState({profilePic: response.data.profile_pic});
              } else {
                //console.error('Invalid profile picture URL:', response.data.profile_pic);
                this.setState({profilePic: "https://spare-app.s3.us-west-1.amazonaws.com/images/default_profile_pic.png"});
              }
        })
    }

    render() {
        /* <img src={!profilePic ? defaultProfilePic : profilePic} alt="Profile" /> */
        return (
            <div className="navbar d-flex justify-content-between align-items-center px-3 py-2">
            <div className="navbar-brand">
                <a href="/dashboard" style={{textDecoration: "none"}}>SPARE</a>
            </div>
            <div className="navbar-links d-flex align-items-center">
                <button className="profile-pic border-0 bg-transparent" aria-haspopup="menu" onClick={this.toggleDropdown}>
                <img src={this.state.profilePic} alt="Profile" className="rounded-circle" />
                </button>
                <div className={`dropdown-menu ${this.state.isOpen ? 'active' : ''}`} role="menu">
                <div className="dropdown-header">
                    <div style={{display: "flex", justifyContent: "flex-start", alignContent: "center"}}>
                    <img src={this.state.profilePic} alt="Profile" className="dropdown-profile-pic" />
                    <div className="dropdown-profile-info">
                        <strong>{this.state.name}</strong>
                        <p>{this.state.email}</p>
                        <p>{this.state.phone}</p>
                    </div>
                    </div>
                </div>
                <ul className="dropdown-list">
                    <li><a href="/dashboard">Dashboard</a></li>
                    <li><a href="/history">Transaction history</a></li>
                    <li><a href="/profile">Account settings</a></li>
                    <li><a href="/help">Help & FAQ</a></li>
                    <li><button onClick={this.logout} className="logout-btn">Log out</button></li>
                </ul>
                </div>
            </div>
            </div>
            );
        }
}


const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            logout: logout,
        },
        dispatch,
    );
const mapStateToProps = state => {
    return {
        token: state.token,
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Header));
